import React from "react"
import {
    Container,
    Box,
    useBreakpointValue,
    useColorModeValue,
    Heading,
    Stack,
    FormControl,
    Text,
    Input,
    FormLabel,
    Checkbox,
    Button,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { HiPaperAirplane, HiX } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom';

import { PasswordField } from './PasswordField'

export default () => {
    let navigate = useNavigate()

    const [ error, setError ] = React.useState(null)
    const [ email, setEmail ] = React.useState(null)
    const [ password, setPassword ] = React.useState(null)
    const [ open, setOpen ] = React.useState(false)
    const cancelRef = React.useRef()

    return <>
        <AlertDialog
            isOpen={open}
            leastDestructiveRef={cancelRef}
            onClose={() => setOpen(false)}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Anmeldung fehlgeschlagen
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {error}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiX />} ref={cancelRef} onClick={() => setOpen(false)}>
                            Schließen
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Container maxW="100%" h="100vh" bg="#E2E8F0">
            <Container maxW="lg" h="100vh" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Stack spacing="8">
                    <Stack spacing="6">
                        <img src="/logo.svg" />
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                                In Ihrem Horoluma Konto anmelden
                            </Heading>
                            <HStack spacing="1" justify="center">
                                <Text color="muted">Noch kein Konto?</Text>
                                <Button variant="link" colorScheme="blue" onClick={() => navigate("/register")}>
                                    Kostenlos registrieren
                                </Button>
                            </HStack>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg="#ffffff"
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <FormControl isRequired>
                                    <FormLabel htmlFor="email">E-Mail-Adresse</FormLabel>
                                    <Input id="email" type="email" required value={email || ""} onChange={e => setEmail(e.target.value)} />
                                </FormControl>
                                <PasswordField value={password || ""} onChange={e => setPassword(e.target.value)} />
                            </Stack>
                            <HStack justify="space-between">
                                <Checkbox defaultIsChecked>Anmeldedaten merken</Checkbox>
                                <Button variant="link" colorScheme="blue" size="sm" onClick={() => navigate("/forgot-password")}>
                                    Passwort vergessen?
                                </Button>
                            </HStack>
                            <Stack spacing="6">
                                <Button leftIcon={<HiPaperAirplane />} colorScheme="blue" disabled={!email || !password} onClick={() => {
                                    fetch(window.watchstorage.backendUrl + "/api/v1/auth/login", {
                                        method: "POST",
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            email: (email || "").toLowerCase(),
                                            password
                                        })
                                    }).then(result => {
                                        if(result.status === 400) {
                                            setError("Bitte überprüfen Sie Ihre Eingaben auf Richtigkeit und versuchen Sie es erneut.")
                                            setOpen(true)
                                        } else if(result.status === 404) {
                                            setError("Ungültige Anmeldedaten. Bitte überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort erneut.")
                                            setOpen(true)
                                        } else if(result.status === 201) {
                                            return result.text()
                                        }

                                        return null
                                    }).then(bearerToken => {
                                        if(bearerToken) {
                                            document.cookie = `Authorization=Bearer ${bearerToken}`
                                            navigate("/dashboard")
                                        }
                                    }).catch(e => {
                                        console.log(e)
                                        setError("Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.")
                                        setOpen(true)
                                    })
                                }}>Anmelden</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Container>
    </>
}
