import React from "react"
import {
    Container,
    Box,
    useBreakpointValue,
    useColorModeValue,
    Heading,
    Stack,
    Text,
    Button,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { HiLogin, HiSave, HiX } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { PasswordField } from '../Login/PasswordField'

export default () => {
    let navigate = useNavigate()
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    })

    const { token, email } = params

    const [ error, setError ] = React.useState(null)
    const [ open, setOpen ] = React.useState(false)
    const [ successOpen, setSuccessOpen ] = React.useState(false)

    const [ password, setPassword ] = React.useState(null)
    const [ passwordRepeat, setPasswordRepeat ] = React.useState(null)
    const successCancelRef = React.useRef()
    const cancelRef = React.useRef()

    return <>
        <AlertDialog
            isOpen={open}
            leastDestructiveRef={cancelRef}
            onClose={() => setOpen(false)}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Passwort zurücksetzen fehlgeschlagen
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {error}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiX />} ref={cancelRef} onClick={() => setOpen(false)}>
                            Schließen
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
            isOpen={successOpen}
            leastDestructiveRef={successCancelRef}
            onClose={() => {
                setSuccessOpen(false)
                navigate("/login")
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Passwort zurücksetzen erfolgreich
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Ihr neues Passwort wurde erfolgreich gespeichert. Sie können sich nun anmelden.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiLogin />} ref={successCancelRef} onClick={() => {
                            setSuccessOpen(false)
                            navigate("/login")
                        }}>
                            Zurück zur Anmeldung
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Container maxW="100%" h="100vh" bg="#E2E8F0">
            <Container maxW="lg" h="100vh" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Stack spacing="8">
                    <Stack spacing="6">
                        <img src="/logo.svg" />
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                                In Ihrem Horoluma Konto anmelden
                            </Heading>
                            <HStack spacing="1" justify="center">
                                <Text color="muted">Geben Sie Ihr neues Passwort ein.</Text>
                            </HStack>
                            <Button variant="link" colorScheme="blue" onClick={() => navigate("/login")}>
                                Zurück zur Anmeldung
                            </Button>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg="#ffffff"
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <PasswordField value={password || ""} onChange={e => setPassword(e.target.value)} label="Neues Passwort" />
                                <PasswordField id="passwordRepeat" label="Neues Passwort wiederholen" value={passwordRepeat || ""} onChange={e => setPasswordRepeat(e.target.value)} />
                            </Stack>
                            <Stack spacing="6">
                                <Button leftIcon={<HiSave />} colorScheme="blue" disabled={!password || !passwordRepeat} onClick={() => {
                                    if(password !== passwordRepeat) {
                                        setError("Die Passwörter stimmen nicht überein.")
                                        setOpen(true)
                                    } else {
                                        fetch(window.watchstorage.backendUrl + "/api/v1/auth/save-password", {
                                            method: "POST",
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                password,
                                                token,
                                                email: email.replace(/ /g, "+")
                                            })
                                        }).then(result => result.json()).then(data => {
                                            if(data === true) {
                                                setSuccessOpen(true)
                                            } else {
                                                if(data.message && data.message.includes("password")) {
                                                    setError("Ihr Passwort muss mindestens acht Zeichen beinhalten.")
                                                    setOpen(true)
                                                } else {
                                                    setError("Ein unbekannter Fehler ist aufgetreten. Bitte fordern Sie einen neuen Link an, um Ihr Passwort zurückzusetzen.")
                                                    setOpen(true)
                                                }
                                            }
                                        }).catch(e => {
                                            console.log(e)
                                            setError("Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.")
                                            setOpen(true)
                                        })
                                    }
                                }}>Speichern</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Container>
    </>
}
