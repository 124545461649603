import React, { Component, useRef, useState } from 'react';
import { FormControl, Input, Switch, Tooltip } from '@chakra-ui/react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button, Alert, AlertIcon, Textarea, Card, Image, FormLabel, Checkbox,
} from '@chakra-ui/react';
import { PictureComponentService } from "./CreateWatchModal";
import { Step, Steps } from 'chakra-ui-steps';
import {HiAnnotation, HiCamera, HiClock, HiPaperAirplane, HiTrash, HiUpload} from 'react-icons/hi';
import styled from 'styled-components';
import { Camera } from 'react-camera-pro';
import { serviceMap, restaurationMap, optionalMap, dialMap } from "./CreateWatchModal";
import { PictureComponent } from "./CreateWatchModal";

const StyledPictureParent = styled.div`
    display: grid;
    grid-auto-flow: row;
`

const StyledButton = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`

const TakePhotoButton = styled(StyledButton)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`


const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Control = styled.div`
  top: 0;
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;

  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }

  @media (max-width: 400px) {
    padding: 10px;
  }
`

const ChangeFacingCameraButton = styled(StyledButton)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 1;
    cursor: default;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
  }
`

function CameraComponent(props) {
    const [numberOfCameras, setNumberOfCameras] = useState(0)
    const camera = useRef(null)

    return (
        <Wrapper>
            <Camera ref={camera} aspectRatio="cover" numberOfCamerasCallback={setNumberOfCameras} />
            <Control>
                <TakePhotoButton
                    onClick={() => {
                        if (camera.current) {
                            const photo = camera.current.takePhoto()

                            props.onPhotoTaken(photo)
                        }
                    }}
                />
                <ChangeFacingCameraButton
                    disabled={numberOfCameras <= 1}
                    onClick={() => {
                        if (camera.current) {
                            camera.current.switchCamera()
                        }
                    }}
                />
            </Control>
        </Wrapper>
    );
}

const fileUploadService = React.createRef()

export default class extends Component {
    state = { serviceMap: [], restaurationMap: [], dialMap: [], optionalMap: [], step: 0, images: [], consultation: false }

    constructor(props) {
        super(props)

        this.onClose = this.onClose.bind(this)
        this.open = this.open.bind(this)
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    open(watch) {
        this.setState({ open: true, step: 0, watch, images: [], description: null, consultation: false, serviceMap: [], restaurationMap: [], dialMap: [], optionalMap: [], saveLoading: false, cameraModalOpen: false })
    }

    onClose() {
        this.setState({ open: false })
    }

    takePhoto(type) {
        this.setState({ cameraModalOpen: true, open: false })
    }

    render() {
        if(this.state.serviceCameraModalOpen) return <CameraComponent onPhotoTaken={image => {
            this.setState({ serviceCameraModalOpen: false, open: true, images: [ ...this.state.images, { data: image, description: null } ] })
        }} />

        return <Modal closeOnOverlayClick={false} isOpen={this.state.open} onClose={this.onClose}>
            <ModalOverlay />
            <ModalContent maxW="800px">
                <ModalHeader>Service anfragen</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Steps activeStep={this.state.step}>
                        {[
                            {
                                label: 'Leistungen',
                                content: <div style={{ width: "100%", display: "grid", gridGap: 32, gridTemplateColumns: "1fr 1fr", marginTop: 16 }}>
                                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, height: "min-content" }}>
                                        <FormLabel>Servicebedarf / Mängel an der Uhr</FormLabel>
                                        {Object.keys(serviceMap).map((key, index) => <Checkbox isChecked={this.state.serviceMap.includes(key)} onChange={() => {
                                            if(this.state.serviceMap.includes(key)) this.setState({ serviceMap: this.state.serviceMap.filter(sm => sm !== key) })
                                            else this.setState({ serviceMap: [ ...this.state.serviceMap, key ] })
                                        }} key={index}>{serviceMap[key]}</Checkbox>)}
                                        <FormLabel>Gehäuse und Armband</FormLabel>
                                        {Object.keys(restaurationMap).map((key, index) => <Checkbox isChecked={this.state.restaurationMap.includes(key)} onChange={() => {
                                            if(this.state.restaurationMap.includes(key)) this.setState({ restaurationMap: this.state.restaurationMap.filter(sm => sm !== key) })
                                            else this.setState({ restaurationMap: [ ...this.state.restaurationMap, key ] })
                                        }} key={index}>{restaurationMap[key]}</Checkbox>)}
                                    </div>
                                    <div style={{ display: "grid", gridAutoFlow: "row", gridGap: 16, height: "min-content" }}>
                                        <FormLabel>Zifferblatt und Zeiger</FormLabel>
                                        {Object.keys(dialMap).map((key, index) => <Checkbox isChecked={this.state.dialMap.includes(key)} onChange={() => {
                                            if(this.state.dialMap.includes(key)) this.setState({ dialMap: this.state.dialMap.filter(sm => sm !== key) })
                                            else this.setState({ dialMap: [ ...this.state.dialMap, key ] })
                                        }} key={index}>{dialMap[key]}</Checkbox>)}
                                        <FormLabel>Optional</FormLabel>
                                        {Object.keys(optionalMap).map((key, index) => <Checkbox isChecked={this.state.optionalMap.includes(key)} onChange={() => {
                                            if(this.state.optionalMap.includes(key)) this.setState({ optionalMap: this.state.optionalMap.filter(sm => sm !== key) })
                                            else this.setState({ optionalMap: [ ...this.state.optionalMap, key ] })
                                        }} key={index}>{optionalMap[key]}</Checkbox>)}
                                    </div>
                                </div>
                            },
                            {
                                label: 'Bilder',
                                content: <>
                                    <input
                                        type="file"
                                        ref={fileUploadService}
                                        accept="image/*"
                                        onChange={data => {
                                            const getBase64 = file => {
                                                let reader = new FileReader();
                                                reader.readAsDataURL(file);
                                                reader.onload = () => {
                                                    const base64 = reader.result

                                                    this.setState({ images: [ ...this.state.images, { data: base64, description: null } ] })
                                                };
                                            };

                                            try {
                                                getBase64(data.target.files[0])
                                            }  catch(e) {}
                                        }}
                                        style={{ opacity: "0", visibility: "hidden" }}
                                    />
                                    <div style={{ display: "grid", gridGap: 16, gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                                        {this.state.images.map((image, index) => <PictureComponentService
                                            key={index}
                                            isDisabled={this.state.saveLoading}
                                            image={image.data}
                                            description={image.description}
                                            onChange={description => {
                                                let images = this.state.images.map((img, ind) => {
                                                    if(ind !== index) return img

                                                    return { ...img, description }
                                                })

                                                this.setState({ images })
                                            }}
                                            setImageNull={() => this.setState({ images: this.state.images.filter((_, ind) => ind != index) })}
                                        />)}
                                    </div>
                                    <div style={{ display: "grid", gridAutoFlow: "column", gridGap: 16, marginTop: 16 }}>
                                        <Button isDisabled={this.state.saveLoading} leftIcon={<HiCamera />} variant='solid' colorScheme='blue' onClick={() => this.setState({ serviceCameraModalOpen: true, open: false })}>
                                            Servicebild mit Kamera schießen
                                        </Button>
                                        <Button isDisabled={this.state.saveLoading} leftIcon={<HiUpload style={{ marginInlineEnd: 4 }} />} variant='outline' colorScheme='blue' onClick={() => {
                                            fileUploadService.current.click()
                                        }}>
                                            Servicebild von Gerät hochladen
                                        </Button>
                                    </div>
                                </>
                            },
                            {
                                label: 'Anfrage',
                                content: <div style={{ paddingTop: 16 }}>
                                    <FormControl display='flex' alignItems='center'>
                                        <FormLabel mb='0'>
                                            Ich wünsche eine persönliche Beratung zur Bearbeitung meiner Uhr.
                                        </FormLabel>
                                        <Switch isChecked={this.state.consultation} size="lg" onChange={e => {
                                            if(!this.state.saveLoading) this.setState({ consultation: e.target.checked })
                                        }} />
                                    </FormControl>
                                    {this.state.consultation && <FormControl>
                                        <FormLabel style={{ marginTop: 16 }}>Telefonnummer (optional)</FormLabel>
                                        <Input
                                          size="lg"
                                          placeholder="Die Angabe Ihrer Telefonnr. erleichtert die Beratung"
                                          onChange={value => this.setState({ telephone: value.target.value })}
                                          value={this.state.telephone}
                                        />
                                    </FormControl>}
                                    <Textarea isReadOnly={this.state.saveLoading} style={{ marginTop: 16 }} value={this.state.description} onChange={e => this.setState({ description: e.target.value })} placeholder="Solltest du noch Anmerkungen / Wünsche zur Anfrage haben, gerne hier notieren." />
                                </div>
                            }
                        ].map(({ label, content }, index) => (
                            <Step label={label} key={label} onClick={() => {
                                if(index < this.state.step && !this.state.saveLoading) this.setState({ step: index })
                            }}>
                                {content}
                            </Step>
                        ))}
                    </Steps>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.onClose} variant='ghost' style={{ marginRight: 16 }}>
                        Abbrechen
                    </Button>
                    <Tooltip hasArrow placement="left" label={(this.state.step === 1 && this.state.images.length === 0) ? "Bitte hänge mindestens ein Service-relevantes Bild an." : null}>
                        <Button isDisabled={this.state.step === 1 && this.state.images.length === 0} isLoading={this.state.saveLoading} colorScheme="green" onClick={() => {
                            if(this.state.step === 0) this.setState({ step: 1 })
                            else if(this.state.step === 1) this.setState({ step: 2 })
                            else if(this.state.step === 2) {
                                this.setState({ saveLoading: true })

                                fetch(window.watchstorage.backendUrl + "/api/v1/watchServices", {
                                    method: "POST",
                                    credentials: "include",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${global.WatchStorage.session.token}`
                                    },
                                    body: JSON.stringify({
                                        watchId: this.state.watch.id,
                                        generalServices: this.state.serviceMap,
                                        restaurationServices: this.state.restaurationMap,
                                        dialServices: this.state.dialMap,
                                        optionalServices: this.state.optionalMap,
                                        images: this.state.images,
                                        consultation: this.state.consultation,
                                        description: this.state.description,
                                        telephone: this.state.telephone
                                    })
                                }).then(async res => {
                                    this.setState({ open: false })

                                    this.props.refresh()

                                    if(res.status === 201) {
                                        window.toast({
                                            title: 'Service angefragt!',
                                            description: 'Wir haben deine Anfrage erhalten und werden und schnellstmöglich bei dir melden.',
                                            status: 'success',
                                            duration: 9000,
                                            isClosable: true,
                                        })
                                    } else {
                                        window.toast({
                                            title: 'Ein Fehler ist aufgetreten.',
                                            description: res.statusText,
                                            status: 'error',
                                            duration: 9000,
                                            isClosable: true,
                                        })

                                        console.log(res)
                                    }
                                })
                            }
                        }} mr={3}>Weiter</Button>
                    </Tooltip>
                </ModalFooter>
            </ModalContent>
        </Modal>
    }
}
