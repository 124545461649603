import React from "react"
import {
    Container,
    Box,
    useBreakpointValue,
    useColorModeValue,
    Heading,
    Stack,
    FormControl,
    Text,
    Input,
    FormLabel,
    Checkbox,
    Button,
    HStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from '@chakra-ui/react'
import { HiPaperAirplane, HiX, HiLogin } from 'react-icons/hi'

import { PasswordField } from '../Login/PasswordField'
import { useNavigate } from 'react-router-dom';

export default () => {
    let navigate = useNavigate()

    const [ error, setError ] = React.useState(null)
    const [ email, setEmail ] = React.useState(null)
    const [ username, setUsername ] = React.useState(null)
    const [ accepted, setAccepted ] = React.useState(false)
    const [ password, setPassword ] = React.useState(null)
    const [ passwordRepeat, setPasswordRepeat ] = React.useState(null)
    const [ open, setOpen ] = React.useState(false)
    const [ successOpen, setSuccessOpen ] = React.useState(false)
    const cancelRef = React.useRef()
    const successCancelRef = React.useRef()

    return <>
        <AlertDialog
            isOpen={open}
            leastDestructiveRef={cancelRef}
            onClose={() => setOpen(false)}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Registrierung fehlgeschlagen
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {error}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiX />} ref={cancelRef} onClick={() => setOpen(false)}>
                            Schließen
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <AlertDialog
            isOpen={successOpen}
            leastDestructiveRef={successCancelRef}
            onClose={() => {
                setSuccessOpen(false)
                navigate("/login")
            }}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Registrierung erfolgreich
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Ihre Registrierung war erfolgreich. Sie erhalten in Kürze eine E-Mail mit Ihrer Registrierungsbestätigung.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button leftIcon={<HiLogin />} ref={successCancelRef} onClick={() => {
                            setSuccessOpen(false)
                            navigate("/login")
                        }}>
                            Zurück zur Anmeldung
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        <Container maxW="100%" h="100vh" bg="#E2E8F0">
            <Container maxW="lg" h="100vh" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
                <Stack spacing="8">
                    <Stack spacing="6">
                        <img src="/logo.svg" />
                        <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                            <Heading size={useBreakpointValue({ base: 'xs', md: 'sm' })}>
                                Neues Horoluma Konto erstellen
                            </Heading>
                            <HStack spacing="1" justify="center">
                                <Text color="muted">Sie haben bereits ein Konto?</Text>
                                <Button variant="link" colorScheme="blue"  onClick={() => navigate("/login")}>
                                    Anmelden
                                </Button>
                            </HStack>
                        </Stack>
                    </Stack>
                    <Box
                        py={{ base: '0', sm: '8' }}
                        px={{ base: '4', sm: '10' }}
                        bg="#ffffff"
                        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
                        borderRadius={{ base: 'none', sm: 'xl' }}
                    >
                        <Stack spacing="6">
                            <Stack spacing="5">
                                <FormControl isRequired>
                                    <FormLabel htmlFor="username">Benutzername</FormLabel>
                                    <Input id="username" type="username" required value={username || ""} onChange={e => setUsername(e.target.value)} />
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel htmlFor="email">E-Mail-Adresse</FormLabel>
                                    <Input id="email" type="email" required value={email || ""} onChange={e => setEmail(e.target.value)} />
                                </FormControl>
                                <PasswordField value={password || ""} onChange={e => setPassword(e.target.value)} />
                                <PasswordField id="passwordRepeat" label="Passwort wiederholen" value={passwordRepeat || ""} onChange={e => setPasswordRepeat(e.target.value)} />
                            </Stack>
                            <HStack justify="space-between">
                                <Checkbox onChange={e => setAccepted(e.target.checked)}>Ich akzeptiere die AGB, Nutzungsbedingungen und den Datenschutzhinweis.</Checkbox>
                            </HStack>
                            <Stack spacing="6">
                                <Button leftIcon={<HiPaperAirplane />} colorScheme="blue" disabled={!email || !password || !passwordRepeat || !accepted || !username} onClick={() => {
                                    if(password !== passwordRepeat) {
                                        setError("Die Passwörter stimmen nicht überein.")
                                        setOpen(true)
                                    } else {
                                        fetch(window.watchstorage.backendUrl + "/api/v1/auth/register", {
                                            method: "POST",
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                email: email.toLowerCase(),
                                                password,
                                                name: username
                                            })
                                        }).then(result => result.json()).then(data => {
                                            if(data.id) {
                                                setSuccessOpen(true)
                                            } else if(data.statusCode === 400) {
                                                if(data.message.find(msg => msg.includes("password"))) {
                                                    setError("Ihr Passwort muss mindestens acht Zeichen beinhalten.")
                                                    setOpen(true)
                                                } else if(data.message.find(msg => msg.includes("email"))) {
                                                    setError("Bitte überprüfen Sie Ihre E-Mail-Adresse.")
                                                    setOpen(true)
                                                } else if(data.message.find(msg => msg.includes("name"))) {
                                                    setError("Ihr Benutzername muss mindestens drei Zeichen enthalten und kann nur Zahlen und Buchstaben enthalten.")
                                                    setOpen(true)
                                                } else {
                                                    setError("Ein unbekannter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Angaben und probieren Sie es erneut.")
                                                    setOpen(true)
                                                }
                                            } else if(data.statusCode === 409) {
                                                setError("Diese E-Mail-Adresse ist bereits registriert.")
                                                setOpen(true)
                                            } else {
                                                setError("Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.")
                                                setOpen(true)
                                            }
                                        }).catch(e => {
                                            console.log(e)
                                            setError("Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.")
                                            setOpen(true)
                                        })
                                    }
                                }}>Registrieren</Button>
                            </Stack>
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Container>
    </>
}
